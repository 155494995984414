import { ReactNode } from 'react';
import { useUser } from 'stores/userStore';
import Page from '@/components/Page';
import PageLayout from '@/components/PageLayout';
import ContentSection from '@/components/ContentSection';
import Spinner, { SpinnerLocation } from '@/components/Spinner';
import styles from './styles/AuthPage.module.scss';

export default function AuthPage({ children }: { children: ReactNode }) {
  const user = useUser();

  return (
    <Page>
      <PageLayout>
        <ContentSection>
          <div className={styles.Form}>
            {!user.isResolved && <Spinner location={SpinnerLocation.CENTER} />}
            {user.isLoggedIn && <div className={styles.Message}>You are logged in</div>}
            {user.isResolved && !user.isLoggedIn && children}
          </div>
        </ContentSection>
      </PageLayout>
    </Page>
  );
}
